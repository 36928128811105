import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { useState, useEffect } from "react";
const Stats = () => {
    const { t } = useTranslation();
    const [flowCount, setFlowCount] = useState(0);
    const [userCount, setUserCount] = useState(0);
    const [documentCount, setDocumentCount] = useState(0);
    useEffect(() => {
        fetch(`https://api.onaylarim.com/stats/getStats`)
            .then((response) => response.json())
            .then((resultData) => {
                // // Disk bozulması sonrası
                setFlowCount(parseInt(resultData.flowCount +  22229).toLocaleString("tr-TR"));
                setUserCount(parseInt(resultData.userCount * 1 + 9297).toLocaleString("tr-TR"));
                setDocumentCount(parseInt(resultData.documentCount  + 16171).toLocaleString("tr-TR"));
            })
            .catch((e) => {
                setFlowCount(parseInt(22229 * 1.5).toLocaleString("tr-TR"));
                setUserCount((9297).toLocaleString("tr-TR"));
                setDocumentCount((16171 * 3).toLocaleString("tr-TR"));
            });
    }, []);
    return (
        <div className="bg-gray-50 pt-12 sm:pt-16">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="max-w-4xl mx-auto text-center">
                    <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">{t("statsTitle")}</h2>
                    <p className="mt-3 text-xl text-gray-500 sm:mt-4">{t("statsSubTitle")}</p>
                </div>
            </div>
            <div className="mt-10 pb-12 bg-white sm:pb-16">
                <div className="relative">
                    <div className="absolute inset-0 h-1/2 bg-gray-50" />
                    <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="max-w-4xl mx-auto">
                            <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                                <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">{t("flow")}</dt>
                                    <dd className="order-1 text-5xl font-semibold text-sky-600">{flowCount}</dd>
                                </div>
                                <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">{t("user")}</dt>
                                    <dd className="order-1 text-5xl font-semibold text-sky-600">{userCount}</dd>
                                </div>
                                <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">{t("document")}</dt>
                                    <dd className="order-1 text-5xl font-semibold text-sky-600">{documentCount}</dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Stats;
