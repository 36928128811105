/* with_background_image_and_overlapping_cards */
import React from "react";

import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
let posts = [
    {
        title: "Sözleşmelerde Hız ve Güvenlik - E-İmza ile Tanışın!",
        href: "https://www.seneka.com.tr/blog/2025-01-05-e-imza-ve-sozlesme",
        category: { name: "Makale", href: "https://www.seneka.com.tr/blog/2025-01-05-e-imza-ve-sozlesme" },
        description: "Dijitalleşme, iş dünyasında ve günlük hayatımızda hızla köklü değişimlere yol açıyor. Bu değişikliklerin en belirgin örneklerinden biri de sözleşme imzalama süreçlerindeki devrimdir....",
        date: "Ocak 5, 2025",
        datetime: "2025-01-05",
        imageUrl: "https://www.seneka.com.tr/images/blog/e-imza-ve-sozlesme.jpg",
        readingTime: "5 dakika",
        author: {
            name: "Dilara K.",
            title: "ONAYLARIM Destek Ekibi",
            imageUrl: "https://www.seneka.com.tr/images/blog/authors/dilarak.jpg",
        },
    },
    {
        title: "İşletme Sahiplerinin e-İmza ile Elde Edebileceği Avantajlar",
        href: "https://www.seneka.com.tr/blog/2024-09-25-isletme-sahiplerinin",
        category: { name: "Makale", href: "https://www.seneka.com.tr/blog/2024-09-25-isletme-sahiplerinin" },
        description: "Günümüzde işletme sahipleri için verimliliği artırmak ve süreçleri daha güvenilir hale getirmek büyük önem taşır. Dijitalleşen dünyada e-imza, Kayıtlı Elektronik Posta (KEP) ve güvenli dosya transferi protokolü (sFTP) gibi teknolojiler....",
        date: "Eylül 25, 2024",
        datetime: "2024-09-25",
        imageUrl: "https://www.seneka.com.tr/images/blog/isletme-sahiplerinin.jpg",
        readingTime: "5 dakika",
        author: {
            name: "Bengi O.",
            title: "ONAYLARIM Destek Ekibi",
            imageUrl: "https://www.seneka.com.tr/images/blog/authors/bengi.jpg",
        },
    },
    {
        title: "Hukuk Alanında e-İmza Kullanım Alanları ve Avantajları",
        href: "https://www.seneka.com.tr/blog/2024-08-28-e-imza-hukuk",
        category: { name: "Makale", href: "https://www.seneka.com.tr/blog/2024-08-28-e-imza-hukuk" },
        description: "Elektronik imza (e-imza), dijitalleşmenin hız kazandığı günümüzde önemli bir yapı taşı haline gelmiştir. Sağlık sektöründen hukuki belgeleme süreçlerine kadar birçok alanda e-imza önemli bir rol oynamaktadır....",
        date: "Ağustos 28, 2024",
        datetime: "2024-08-28",
        imageUrl: "https://www.seneka.com.tr/images/blog/e-imza-hukuk.jpg",
        readingTime: "5 dakika",
        author: {
            name: "Bengi O.",
            title: "ONAYLARIM Destek Ekibi",
            imageUrl: "https://www.seneka.com.tr/images/blog/authors/bengi.jpg",
        },
    },
    {
        title: "e-İmza Kullanmanız İçin 5 Neden",
        href: "https://www.seneka.com.tr/blog/2023-01-13-e-imza-kullanin",
        category: { name: "Makale", href: "https://www.seneka.com.tr/blog/2023-01-13-e-imza-kullanin" },
        description: "Biliyorsunuz ki dijital dönüşüm sayesinde özellikle pandemiden sonra, fiziksel olarak gerçekleştirdiğimiz birçok işlemi elektronik ortama taşıdık. Bu işlemlerin kapsamına, elektronik ortamda imza atabilmek de dahil oldu....",
        date: "Ocak 13, 2023",
        datetime: "2023-01-13",
        imageUrl: "https://www.seneka.com.tr/images/blog/e-imza-kullanin.jpg",
        readingTime: "5 dakika",
        author: {
            name: "Bengi O.",
            title: "ONAYLARIM Destek Ekibi",
            imageUrl: "https://www.seneka.com.tr/images/blog/authors/bengi.jpg",
        },
    },
    {
        title: "Gizlilik Sözleşmesi Nedir? Nasıl Hazırlanır?",
        href: "https://www.seneka.com.tr/blog/2023-01-05-gizlilik-sozlesmesi",
        category: { name: "Makale", href: "https://www.seneka.com.tr/blog/2023-01-05-gizlilik-sozlesmesi" },
        description: "Bu yazımızda size gizlilik sözleşmesinin içeriğinden, kapsamından, hazırlanma aşamasında dikkat edilmesi gereken hususlardan, Kişisel Verileri Koruma Kanunu ile ilişkisinden bahsedeceğiz....",
        date: "Ocak 5, 2023",
        datetime: "2023-01-05",
        imageUrl: "https://www.seneka.com.tr/images/blog/bilgileriniz-calinmasin.jpg",
        readingTime: "5 dakika",
        author: {
            name: "Bengi O.",
            title: "ONAYLARIM Destek Ekibi",
            imageUrl: "https://www.seneka.com.tr/images/blog/authors/bengi.jpg",
        },
    },
    {
        title: "Yazışmalarınızı KEP ile Güvenli Bir Şekilde Gerçekleştirin",
        href: "https://www.seneka.com.tr/blog/2022-12-29-kep-ile-yazisma-yapma",
        category: { name: "Makale", href: "https://www.seneka.com.tr/blog/2022-12-29-kep-ile-yazisma-yapma" },
        description: "İş hayatımızda hatta özel hayatımızda bile çoğu yazışmamızı, belge paylaşımlarımızı e-posta üzerinden yapıyoruz. Kullanım kolaylığı, özellikle mobil cihazlardan da erişebiliyor olmamız e-postanın bize en cazip gelen özelliklerinden bir tanesi....",
        date: "Aralık 29, 2022",
        datetime: "2022-12-29",
        imageUrl: "https://www.seneka.com.tr/images/blog/yazismalarinizi-kep-ile-yapin.jpg",
        readingTime: "5 dakika",
        author: {
            name: "Bengi O.",
            title: "ONAYLARIM Destek Ekibi",
            imageUrl: "https://www.seneka.com.tr/images/blog/authors/bengi.jpg",
        },
    }

];

// import ReactPlayer from "react-player";
{
    /* <ReactPlayer light={Sozlesmeimzalamav2PosterInitial} controls="true" url="https://www.youtube.com/watch?v=ysz5S6PUM-U" />; */
}

const baseurl = "https://guide.onaylarim.com";

const BlogItems = () => {
    const { t } = useTranslation();

    return (
        <div id="blogItems" className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
            <div className="absolute inset-0">
                <div className="bg-white h-1/3 sm:h-2/3" />
            </div>
            <div className="relative max-w-7xl mx-auto">
                <div className="text-center">
                    <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">{t("blogs-title")}</h2>
                    <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">{t("blogs-subTitle")}</p>
                </div>
                <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none  ">
                    {posts.map((post) => (
                        <div key={post.title} className="flex flex-col rounded-lg shadow-lg overflow-hidden group">
                            <div className="flex-shrink-0">
                                <img className="h-48 w-full object-cover" src={post.imageUrl} alt="" />
                            </div>
                            <div className="flex-1 bg-white group-hover:bg-slate-100 p-6 flex flex-col justify-between">
                                <div className="flex-1">
                                    <p className="text-sm font-medium text-indigo-600">
                                        <a href={post.category.href} className="hover:underline">
                                            {post.category.name}
                                        </a>
                                    </p>
                                    <a href={post.href} className="block mt-2">
                                        <p className="text-xl font-semibold text-gray-900">{post.title}</p>
                                        <p className="mt-3 text-base text-gray-500">{post.description}</p>
                                    </a>
                                </div>
                                <div className="mt-6 flex items-center">
                                    <div className="flex-shrink-0">
                                        <a href={post.author.href}>
                                            <span className="sr-only">{post.author.name}</span>
                                            <img className="h-10 w-10 rounded-full" src={post.author.imageUrl} alt="" />
                                        </a>
                                    </div>
                                    <div className="ml-3">
                                        <p className="text-sm font-medium text-gray-900">{post.author.name}</p>
                                        <div className="flex space-x-1 text-sm text-gray-500">
                                            <time dateTime={post.datetime}>{post.date}</time>
                                            <span aria-hidden="true">&middot;</span>
                                            <span>{post.readingTime}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="max-w-4xl mx-auto mt-10 text-lg text-center">
                    <Trans i18nKey="blogs-all">
                        Sorunuzun cevabını bulamadıysanız{" "}
                        <a href="https://www.seneka.com.tr/blogPosts/" className="text-base font-medium text-sky-700 hover:text-sky-600 whitespace-nowrap">
                            Soru ve Cevaplar
                        </a>{" "}
                        sayfasına göz atabilirsiniz.
                    </Trans>
                </div>
            </div>
        </div>
    );
};
export default BlogItems;
